import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import styled, { css } from 'react-emotion'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { Pager } from '../utils/styles'
import Img from 'gatsby-image'
import Seo from '../components/Seo'
import { mq, colors, PageHeadings } from '../utils/styles'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Episode from '../components/Episode'
import Helmet from 'react-helmet'
import Socials from '../components/Socials'
import { FlatSocials } from '../components/FlatSocials'
import Player from '../components/Player'

const Post = styled('div')`
  display: flex;
  justify-content: center;
  ${mq({
  'flex-wrap': ['wrap', 'nowrap', 'nowrap', 'nowrap'],
  'padding-bottom': ['0rem', '0.5rem', '0.5rem', '0.5rem'],
})};
`

const EmailSubmitButton = styled('a')`
  font-family: 'Pangolin', cursive;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  color: #fff;
  margin-top: 1rem;
  background-color: ${colors.red};
  cursor: pointer;
  // font-size: 0.8rem;
  line-height: 1.15;
  text-decoration: none;
  &:hover {
    background-color: ${colors.lightRed};
    color: black;
  }
`
const EmailInput = styled('input')`
  width: 100%;
  max-width: 20rem;
  font-family: 'Pangolin', cursive;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-width: 1px;
  border-radius: 0.25rem;
  line-height: 1.15;
  margin: 0;
  display: block;
`

const SuccessMessage = styled('span')`
  padding-left: 1rem;
  color: ${colors.green};
`

const ErrorMessage = styled('div')`
  padding-top: 1rem;
  color: ${colors.red};
`

const RigthSide = styled('div')`
  // padding-left:2rem;
  ${mq({
  'padding-left': ['0rem;', '0rem;', '2rem', '2rem'],

  'min-width': ['100%;', '100%;', '2rem', '2rem'],
})};
  max-width: 32rem;
`

class BlogIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      submitError: false,
      submitMessage: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()
    try {
      typeof window !== 'undefined' &&
        window.gtag('event', 'conversion', {
          send_to: 'AW-972785904/l1l4CNb-sJABEPCR7s8D',
        })
    } catch (e) { }
    try {
      typeof window !== 'undefined' &&
        fbq('track', 'Subscribe', {
          value: '0.00',
          currency: 'USD',
          predicted_ltv: '0.00',
        })
    } catch (e) { }
    addToMailchimp(this.state.value).then(data => {
      if (data.result === 'success') {
        this.setState({
          value: '',
          submitError: false,
          submitMessage: 'Subscribed!',
        })
      } else {
        this.setState({
          submitError: true,
          submitMessage: `Got an error, ${data.msg}`,
        })
      }
    })
  }

  componentWillUnmount() {
    this.setState({ value: '', submitError: false, submitMessage: '' })
  }

  render() {
    // console.log('%cHey developer, shout if you see this', 'font-size: x-large')
    return (
      <Layout location={this.props.location}>
        <Helmet>
          <link
            rel="alternate"
            type="application/rss+xml"
            title={this.props.data.site.siteMetadata.siteName}
            href={this.props.data.site.siteMetadata.podcastFeed}
          />
        </Helmet>
        <Seo
          type="page"
          title={this.props.data.site.siteMetadata.siteName}
          description={this.props.data.site.siteMetadata.siteDescription}
          path={'/'}
        />

        <div
          className={css`
            margin-bottom: 1rem;
          `}
        >
          <FlatSocials />
        </div>
        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          `}
        >
          <div>
            <Img fixed={this.props.data.art.childImageSharp.fixed} />
          </div>
          <RigthSide>
            {this.props.data.allFeedPodcastFeed.edges.map(({ node }, i) => (
              <div
                className={css`
                  margin-top: 0.5rem;
                  margin-bottom: 0.5rem;
                `}
                key={`home-${i}`}
              >
                <Player width="100%" link={node.link} />
              </div>
            ))}
            <p
              className={css`
                margin-bottom: 1rem;
                ${mq({
                display: ['none;', 'block', 'block', 'block'],
              })};
              `}
            >
              <b>Finance for Hippies</b> is a fun podcast for artists, creatives
              and designers wanting to improve their financial literacy and make
              better decisions about their money. Join Ndarkie and TeethSwag on
              their artistic journey to financial freedom and{' '}
              <b>
                Subscribe to our podcast and keep up to date with our latest
                episodes.
              </b>
            </p>

            <div
              className={css`
            margin-bottom: 2rem;
          `}
            >
              <EmailSubmitButton
                rel="noopener"
                target="_blank"
                href={`https://www.youtube.com/channel/UC4LmLFD3wVzEBerdbZ15iSg`}>
                Subscribe on YouTube
              </EmailSubmitButton>
            </div>

            <Link
              className={css`
                text-decoration: none;
              `}
              to={'/episodes/'}
            >
              View more episodes...
            </Link>
          </RigthSide>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
        siteDescription
        siteUrl
        podcastFeed
      }
    }
    ndarki: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "ndarki.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fixed(width: 100, height: 100) {
          # ...GatsbyImageSharpFixed
          ...GatsbyImageSharpFixed_tracedSVG
          }
      }
    }
    teethswag: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "teethswag.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fixed(width: 100, height: 100) {
          # ...GatsbyImageSharpFixed
          ...GatsbyImageSharpFixed_tracedSVG
          }
      }
    }
    art: file(sourceInstanceName: {eq: "assets"} relativePath: { eq: "poster.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        # cropFocus: CENTER
        fixed(width: 300, height: 370) {
          # ...GatsbyImageSharpFixed
          ...GatsbyImageSharpFixed_tracedSVG
          }
      }
    }

    
    # allRssEntry(sort: {fields: [rawDate], order: DESC} limit: 1){
    #   edges{
    #     node{
    #       title
    #       description
    #       image
    #       rawDate(formatString: "MMMM D, YYYY")
    #       pubDate
    #       link
    #       fields{
    #         slug
    #       }
    #     }
    #   }
    # }

    allFeedPodcastFeed(sort: { fields: isoDate, order: DESC }, limit: 1) {
    edges {
      node {
        content
        contentSnippet
        link
        pubDate
        title
        itunes {
          duration
          image {
            attrs {
              href
            }
          }
        }
        fields{
            slug
            localImage{
              relativePath
              childImageSharp{
                fluid(maxWidth: 600 maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                  }
              }
            }
          }
        id
      }
    }
  }
        

    allFile(filter: {sourceInstanceName: {eq: "pages"}, absolutePath: {regex:"/\\.(gif|jpg|jpeg|tiff|png)$/"}}) {
      edges {
        node {
          relativePath
          childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          # cropFocus: CENTER
          fixed(width: 200, height: 180) {
            ...GatsbyImageSharpFixed
           }
          }
        }
      }
    }
  }
`

// allEpisodes(sort: {fields: [date], order: DESC} limit: 1) {
//   edges{
//     node{
//       title
//       date(formatString: "MMMM D, YYYY")
//       description
//       fields{
//         slug
//         file{
//           publicURL
//         }
//       }
//     }
//   }
// }
